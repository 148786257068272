import React, { useEffect, useRef, useState } from 'react'

import { PlusOutlined } from '@ant-design/icons'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { PageContainer, ProTable } from '@ant-design/pro-components'
import type { ActionType } from '@ant-design/pro-components'
import { Badge, Button, Modal, message } from 'antd'

import { AqlInspectionListResult } from 'aql-inspection/aql-inspection'
import { aqlColumns } from 'components/aql-inspection.columns'
import { saveAs } from 'file-saver'
import { navigate } from 'gatsby'
import { generateAqlInspectionFilter } from 'helpers/aql-inspection'
import JSZip from 'jszip'
import { DropdownParams } from 'master-data/dropdown'
import { aqlInspectionListState } from 'models/aql-inspection/aql-inspection-store'
import { countryAtom } from 'models/master-data/master-data-store'
import { userAtom } from 'models/user/user-store'
import { useMutation } from 'react-query'
import { useRecoilState } from 'recoil'
import { useApi } from 'services'
import { AqlApi } from 'services/aql-api'
import { MasterDataApi } from 'services/master-data-api'

import AddNewDrawer from './add-new-drawer'

const JSZipUtils = require('jszip-utils')

// taken from jQuery
function createStandardXHR() {
  try {
    return new window.XMLHttpRequest()
  } catch (e) {}
}

function createActiveXHR() {
  try {
    return new window.ActiveXObject('Microsoft.XMLHTTP')
  } catch (e) {}
}

// Create the request object
let createXHR =
  typeof window !== 'undefined' && window.ActiveXObject
    ? /* Microsoft failed to properly
       * implement the XMLHttpRequest in IE7 (can't request local files),
       * so we use the ActiveXObject when it is available
       * Additionally XMLHttpRequest can be disabled in IE7/IE8 so
       * we need a fallback.
       */
      function () {
        return createStandardXHR() || createActiveXHR()
      }
    : // For all other browsers, use the standard XMLHttpRequest object
      createStandardXHR

JSZipUtils.getBinaryContent = function (path: any, options: any, headers: any) {
  let promise, resolve: any, reject: any
  let callback: any

  if (!options) {
    options = {}
  }

  // backward compatible callback
  if (typeof options === 'function') {
    callback = options
    options = {}
  } else if (typeof options.callback === 'function') {
    // callback inside options object
    callback = options.callback
  }

  if (!callback && typeof Promise !== 'undefined') {
    promise = new Promise(function (_resolve, _reject) {
      resolve = _resolve
      reject = _reject
    })
  } else {
    resolve = function (data: any) {
      callback(null, data)
    }
    reject = function (err: any) {
      callback(err, null)
    }
  }

  /*
   * Here is the tricky part : getting the data.
   * In firefox/chrome/opera/... setting the mimeType to 'text/plain; charset=x-user-defined'
   * is enough, the result is in the standard xhr.responseText.
   * cf https://developer.mozilla.org/En/XMLHttpRequest/Using_XMLHttpRequest#Receiving_binary_data_in_older_browsers
   * In IE <= 9, we must use (the IE only) attribute responseBody
   * (for binary data, its content is different from responseText).
   * In IE 10, the 'charset=x-user-defined' trick doesn't work, only the
   * responseType will work :
   * http://msdn.microsoft.com/en-us/library/ie/hh673569%28v=vs.85%29.aspx#Binary_Object_upload_and_download
   *
   * I'd like to use jQuery to avoid this XHR madness, but it doesn't support
   * the responseType attribute : http://bugs.jquery.com/ticket/11461
   */
  try {
    let xhr = createXHR()

    xhr.open('GET', path, true)

    // recent browsers
    if ('responseType' in xhr) {
      xhr.responseType = 'arraybuffer'
    }

    if (headers) {
      xhr.setRequestHeader('x-access-token', headers['x-access-token'])
    }

    // older browser
    if (xhr.overrideMimeType) {
      xhr.overrideMimeType('text/plain; charset=x-user-defined')
    }

    xhr.onreadystatechange = function (event: any) {
      // use `xhr` and not `this`... thanks IE
      if (xhr.readyState === 4) {
        if (xhr.status === 200 || xhr.status === 0) {
          try {
            resolve(JSZipUtils._getBinaryFromXHR(xhr))
          } catch (err) {
            reject(new Error(err))
          }
        } else {
          reject(
            new Error(
              'Ajax error for ' +
                path +
                ' : ' +
                this.status +
                ' ' +
                this.statusText,
            ),
          )
        }
      }
    }

    if (options.progress) {
      xhr.onprogress = function (e: any) {
        options.progress({
          path: path,
          originalEvent: e,
          percent: (e.loaded / e.total) * 100,
          loaded: e.loaded,
          total: e.total,
        })
      }
    }

    xhr.send()
  } catch (e) {
    reject(new Error(e), null)
  }

  // returns a promise or undefined depending on whether a callback was
  // provided
  return promise
}

const { confirm } = Modal

const AqlInspectionPage = () => {
  const actionRef = useRef<ActionType>()
  const [activeKey, setActiveKey] = useState<React.Key>('all_tab')
  const [userData] = useRecoilState(userAtom)
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [allCount, setAllCount] = useState(0)
  const [trashCount, setTrashCount] = useState(0)
  const [countryDdList, setCountryDdList] = useRecoilState(countryAtom)
  const [aqlInspectionData, setAqlInspectionData] = useRecoilState(
    aqlInspectionListState,
  )
  const [currentPage, setCurrentPage] = useState(1)
  const [actionList, setActionList] = useState<string[]>([])
  const [copyPo, setCopyPo] = useState<any>({})
  const PAGE_SIZE = useRef<number>(5)

  // ANCHOR Mutation
  const aqlMutation = useMutation(async (data: any) => {
    const aqlApi = useApi(AqlApi)
    return aqlApi.index(data)
  })

  const aqlUpdateMutation = useMutation(async (data: any) => {
    const aqlApi = useApi(AqlApi)
    return aqlApi.update(data)
  })

  const aqlDeleteMutation = useMutation(async (data: any) => {
    const aqlApi = useApi(AqlApi)
    return aqlApi.delete(data)
  })

  const aqlPermanentDeleteMutation = useMutation(async (data: any) => {
    const aqlApi = useApi(AqlApi)
    return aqlApi.permanentDelete(data)
  })

  const countryDdMutation = useMutation(async (params: DropdownParams) => {
    const masterDataApi = useApi(MasterDataApi)
    return masterDataApi.dropdown(params)
  })

  // ANCHOR useEffect
  useEffect(() => {
    let al = userData.menu_action?.filter(
      (e: any) => e.path == 'aql-inspection',
    )
    al = al.length ? al[0].action_list : []
    console.log(al)
    setActionList(al)

    countryDdMutation.mutate({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        option: 'country',
      },
    })
  }, [])

  useEffect(() => {
    console.log(countryDdMutation.data)
    if (!countryDdMutation.isError) {
      const countryResult = countryDdMutation.data

      if (countryResult?.kind == 'ok') {
        let countryList = countryResult.data || []

        let countryOptions = countryList.result.map((e: any) => {
          return { label: e.value, value: e.id }
        })
        setCountryDdList(countryOptions)
      }
    }
  }, [countryDdMutation.data, countryDdMutation.isError])

  useEffect(() => {
    if (aqlMutation.data) {
      console.log(aqlMutation.data.data.data)
      setAqlInspectionData(aqlMutation.data.data.data)
    }
  }, [aqlMutation.data, aqlMutation.isError])

  useEffect(() => {
    actionRef.current?.reload()
    setCurrentPage(1)
  }, [activeKey])

  useEffect(() => {
    if (!aqlDeleteMutation.isError && aqlDeleteMutation.data?.kind == 'ok') {
      actionRef.current?.reload()
    } else if (aqlDeleteMutation.isError) {
      message.error("Can't delete the data. Please contact the administrator.")
    }
  }, [aqlDeleteMutation.data, aqlDeleteMutation.isError])

  useEffect(() => {
    if (!aqlUpdateMutation.isError && aqlUpdateMutation.data?.kind == 'ok') {
      actionRef.current?.reload()
    } else if (aqlUpdateMutation.isError) {
      message.error("Can't update the data. Please contact the administrator.")
    }
  }, [aqlUpdateMutation.data, aqlUpdateMutation.isError])

  useEffect(() => {
    if (
      !aqlPermanentDeleteMutation.isError &&
      aqlPermanentDeleteMutation.data?.kind == 'ok'
    ) {
      actionRef.current?.reload()
    } else if (aqlPermanentDeleteMutation.isError) {
      message.error("Can't delete the data. Please contact the administrator.")
    }
  }, [aqlPermanentDeleteMutation.data, aqlPermanentDeleteMutation.isError])

  const download = async (
    record: any,
    key: 'inline' | 'final' | 're-final',
  ) => {
    console.log(record)
    if (!record) {
      return
    }
    // Source: https://gist.github.com/c4software/981661f1f826ad34c2a5dc11070add0f
    var zip = new JSZip()
    var count = 0
    // console.log(selectedFiles.map((e) => e.file_name_original))
    var zipFilename = `report_aql_inspection_${record.po_number}_${key}.zip`
    var urls = [
      `${process.env.API_URL}aqlinspection/excel?report_status=${
        key == 'inline' ? 1 : key == 'final' ? 2 : 3
      }&inspection_type_id=${record.inspection_type_id}&inspection_id=${
        record.id
      }`,
    ]
    // var urls = selectedFiles.map((e) => process.env.API_URL + e.path_real)

    urls.forEach(function (file) {
      var filename = `report_aql_inspection_${record.po_number}_${key}.xlsx`
      // loading a file and add it in a zip file
      // var filepath = process.env.API_URL + file.path_real
      try {
        // JSZipUtils.getBinaryContent(
        //   file,
        //   function (err: any, data: any) {
        //     if (err) {
        //       // console.log(err)
        //       message.error('Can not download this report.')
        //       return
        //       // throw err // or handle the error
        //     }
        //     zip.file(filename, data, { binary: true })
        //     count++
        //     // setDownloadingCount(count)
        //     if (count == urls.length) {
        //       zip.generateAsync({ type: 'blob' }).then(function (content) {
        //         saveAs(content, zipFilename)
        //         // setDownloadingCount(0)
        //       })
        //     }
        //   },
        //   { 'x-access-token': userData.token },
        // )

        saveAs(file, filename)
      } catch (error) {
        message.error('Can not download this report.')
      }
    })
  }

  /**
   * ANCHOR Request Logic
   * @param params
   * @param _sort
   * @param _filter
   * @returns
   */
  const request = async (params: any, _sort: any, _filter: any) => {
    if (
      params &&
      params.pageSize &&
      params.pageSize > 0 &&
      params.pageSize != PAGE_SIZE.current
    ) {
      PAGE_SIZE.current = params.pageSize
    }
    // console.log('request param : ' + JSON.stringify(params))
    if (activeKey == 'all_tab') {
      params.status = 'A'
    } else if (activeKey == 'trash_tab') {
      params.status = 'I'
    }

    // console.log('userData', userData)
    if (userData.role_id == 2) {
      params.customer_id = userData.customer_id
    } else if (userData.role_id == 9) {
      params.supplier_id = userData.supplier_id
    }
    const where = generateAqlInspectionFilter(params)
    // console.log('aql pagesize : ' + PAGE_SIZE.current)
    const data = await aqlMutation.mutateAsync({
      headers: {
        'x-access-token': userData.token,
      },
      params: {
        offset: (currentPage - 1) * PAGE_SIZE.current,
        limit: PAGE_SIZE.current,
      },
      body: {
        where: where,
      },
    })
    if (data.kind == 'ok') {
      let transform_data = data.data
      let page = transform_data.page
      page = Math.floor((page - 1) / PAGE_SIZE.current) + 1
      transform_data.page = page

      if (activeKey == 'all_tab') {
        setAllCount(transform_data.total)
      } else if (activeKey == 'trash_tab') {
        setTrashCount(transform_data.total)
      }

      return transform_data
    }
  }

  const renderBadge = (count: number | string, active = false) => {
    return (
      <Badge
        count={count}
        style={{
          padding: '0 10px',
          marginTop: -2,
          marginLeft: 5,
          color: active ? '#1890FF' : '#999',
          backgroundColor: active ? '#E6F7FF' : '#eee',
        }}
      />
    )
  }

  const onCloseDrawer = (reload = true) => {
    setDrawerVisible(false)
    if (reload) {
      actionRef.current?.reload()
    }
  }

  const onDelete = (inspection_id: number | string) => {
    if (inspection_id) {
      console.log(inspection_id)
      confirm({
        title: 'Do you want to delete this item?',
        icon: <ExclamationCircleOutlined />,
        content: '',
        onOk() {
          aqlDeleteMutation.mutate({
            headers: {
              'x-access-token': userData.token,
            },
            body: [
              {
                aql_inspection_id: inspection_id,
                status: 'I',
              },
            ],
          })
        },
        onCancel() {},
      })
    }
  }

  const onRestore = (inspection_id: number | string) => {
    if (inspection_id) {
      aqlUpdateMutation.mutate({
        headers: {
          'x-access-token': userData.token,
        },
        body: [
          {
            aql_inspection_id: inspection_id,
            status: 'A',
          },
        ],
      })
    }
  }

  const onPermanentDelete = (inspection_id: number | string) => {
    if (inspection_id) {
      confirm({
        title: 'Do you want to delete this item?',
        icon: <ExclamationCircleOutlined />,
        content: '',
        onOk() {
          aqlPermanentDeleteMutation.mutate({
            headers: {
              'x-access-token': userData.token,
            },
            params: {
              aql_inspection_id: inspection_id,
            },
          })
        },
        onCancel() {},
      })
    }
  }

  const onCopyPo = (data: any) => {
    console.log(data)
    let copy = JSON.parse(JSON.stringify(data))
    for (const [key, value] of Object.entries(copy)) {
      if (key == 'po_number' || value == '' || value == null) {
        copy[key] = undefined
      }
    }
    setCopyPo(copy)
    setDrawerVisible(true)
  }

  const getColumns = () => {
    let countryList: any = {}
    countryDdList.forEach((element: any) => {
      countryList[element.label] = element.label
    })

    return aqlColumns(
      countryList,
      currentPage,
      PAGE_SIZE.current,
      onDelete,
      onRestore,
      onPermanentDelete,
      activeKey as string,
      download,
      actionList,
      onCopyPo,
    )
  }

  return (
    <PageContainer
      fixedHeader
      header={{
        title: '',
        breadcrumb: {
          routes: [
            {
              path: '',
              breadcrumbName: 'AQL Inspection',
            },
          ],
        },
      }}
    >
      <ProTable<AqlInspectionListResult>
        columns={getColumns()}
        actionRef={actionRef}
        cardBordered
        scroll={{ x: 1600 }}
        defaultData={[]}
        request={request}
        editable={{
          type: 'multiple',
        }}
        search={{
          labelWidth: 'auto',
        }}
        columnsState={{
          persistenceKey: 'aql-inspectoin-table-column-state',
          persistenceType: 'localStorage',
          onChange(value) {
            console.log('value: ', value)
          },
        }}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: PAGE_SIZE.current,
          showSizeChanger: true,
          onChange: (page) => {
            setCurrentPage(page)
          },
        }}
        dateFormatter="string"
        options={{
          density: true,
        }}
        rowClassName={(record) => {
          return 'row-pointer'
        }}
        // onRow={(record, column) => {
        //   console.log(column)
        //   return {
        //     onClick: (data) => {
        //       console.log(data)
        //       navigate('/aql-inspection/view?id=' + record.id)
        //     },
        //   }
        // }}
        toolbar={{
          menu: {
            type: 'tab',
            activeKey: activeKey,
            items: [
              {
                key: 'all_tab',
                label: (
                  <span>
                    All
                    {renderBadge(allCount, activeKey === 'all_tab')}
                  </span>
                ),
              },
              {
                key: 'trash_tab',
                label: (
                  <span>
                    Trash
                    {renderBadge(trashCount || '0', activeKey === 'trash_tab')}
                  </span>
                ),
              },
            ],
            onChange: (key) => {
              setActiveKey(key as string)
            },
          },
        }}
        toolBarRender={() => [
          actionList.includes('insert') ? (
            <AddNewDrawer
              onClose={onCloseDrawer}
              visible={drawerVisible}
              initialData={copyPo}
            >
              <Button
                key="button"
                icon={<PlusOutlined />}
                type="primary"
                style={{
                  backgroundColor: '#10c469',
                  borderColor: '#10c469',
                }}
                onClick={() => {
                  setCopyPo({})
                  setDrawerVisible(true)
                }}
              >
                Add New
              </Button>
            </AddNewDrawer>
          ) : null,
        ]}
      />
    </PageContainer>
  )
}

export default AqlInspectionPage
